import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { MainContent, Articles } from "./MainContent/Index";

function ForTheFoodies() {
  return (
    <Container>
      <Row>
        <Col>
        {MainContent.map((items) => (
          <>
          <h1>
            {items.title}
          </h1>        
          <p>
          {items.pageDesc}
          </p>
        </>
        ))}
        </Col>
        <Col>
          Image insertion here
        </Col>
      </Row>
      <h2>
        Browse Articles and Newsletter
      </h2>
      <div className="ScrollableDiv">
      <Row xs={1} md={3} className="g-4">
        {Articles.map((items) => (
          <Col>
            <Card>
              <Card.Img variant="top" src="holder.js/100px160" />
              <Card.Body>
                <Card.Title>{items.title}</Card.Title>
                <Card.Text>
                  {items.articleDesc}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          ))}
    </Row>
    </div>
    </Container>
  );
}

export default ForTheFoodies;