import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';



function SignUpNewsLetter() {
  return (
    <>
    <h4>
        Interested in hearing about the release of new articles and podcast episodes - subscribe to the <i>Past Present Tense</i> weekly newsletter and podcast today. 
    </h4>
        <Card>
            <Card.Body>
            <Row>
                <Col>
                Sign up to our weekly newsletter + Podcast conversations where we, as a collective, can mutual learn to ...
                </Col>
                <Col>
            <Col>
            <div class="container">
            <div class="cardEmailSub">
                <h1>Sign Up today!</h1>
                <p>Don't miss out our newly released content, get notified directly to your email!</p>
                <form action="#" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Your email" required/>
                <button name="subscribe" id="mc-embedded-subscribe" class="button" type="submit">Click here to subscribe</button>
                </form>
            </div>
            </div>
            </Col>
                </Col>
            </Row>
            </Card.Body>
        </Card>
    </>
  );
}

export default SignUpNewsLetter;