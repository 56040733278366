import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

function Podcasts() {
  return (
    <CardGroup>
      <Card>
        <Card.Img variant="top" src="holder.js/100px160" />
        <Card.Body>
          <Card.Title>
            The Past. Present. Tense. Podcast
        </Card.Title>
          <Card.Text>
            In our first episode of the Past. Present. Tense. Podcast, Alex and Joseph discuss the world's problems and come up with a 4 point plan where by all should live by haha!
            <br/>
            <b>Episode release date: </b> May 5<sup>th</sup>, 2023
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Last updated 3 mins ago</small>
        </Card.Footer>
      </Card>
    </CardGroup>
  );
}

export default Podcasts;