import React from "react";
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './MainPage.css'

function MonthlyNewsLetter() {
    const width = "100%";
    return ( 
        <React.Fragment>
        <Container>
        <Row>
            <Col>
            <h1>
                Written by Alex
            </h1>
            <h2>
                Weekly newsletter:
            </h2>
            <p>
                Never miss a blog post and receive magical rainbows when you sign up with your email address. One per week & I promise not to spam you.
            </p>
            </Col>
            <Col>
            <div class="container">
            <div class="cardEmailSub">
                <h1>Sign Up today!</h1>
                <p>Don't miss out our newly released content, get notified directly to your email!</p>
                <form action="#" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Your email" required/>
                <button name="subscribe" id="mc-embedded-subscribe" class="button" type="submit">Subscribe Here</button>
                </form>
            </div>
            </div>
            </Col>
        </Row>
        </Container>
        </React.Fragment>
        );
    }

    export default MonthlyNewsLetter