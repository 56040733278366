import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './NewSnippet.css'
import { Link } from 'react-router-dom';

function NewSnippet() {
  return (
    <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title className='cardTitle'>Apophenia</Card.Title>
              <Card.Text className='cardText'>
                <Link to='/Apophenia' className='cardText'>
                  When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
                </Link>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    April 17<sup>th</sup> 2023 | 5 mins read | Alex Wildwood
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title className='cardTitle'>Gumption Gaps</Card.Title>
              <Card.Text className='cardText'>
                <Link to='/GumptionGaps' className='cardText'>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Link>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 3<sup>rd</sup> 2023 | 5 mins read | Alex Wildwood
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title className='cardTitle'>Pádraic</Card.Title>
              <Card.Text className='cardText'>
                <Link to='/Pádraic' className='cardText'>
                  The film The Banshee of Inisherin is a brilliant film. The story is great, the setting is beautiful, the characters are fascinating, and even the frickin' donkey is amazing.
                </Link>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 5<sup>th</sup> 2023 | 5 mins read | Alex Wildwood
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title className='cardTitle'>Give Up</Card.Title>
                <Card.Text className='cardText'>
                  <Link to='/GiveUp' className='cardText'>
                    Here's a fun question to ask yourself; whatever you want to achieve, your goal for the future or the state of being you wish to get to is, what if it's further away than you think? 
                  </Link>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 10<sup>th</sup> 2023 | 5 mins read | Alex Wildwood
                </small>
            </Card.Footer>
          </Card>
        </Col>
    </Row>
  );
}

export default NewSnippet;