

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useState } from 'react';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext } from "react";
import './Navbar.css';
import { Link } from 'react-router-dom';

export function NavBar() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize", 
      () => {
        const width = window.innerWidth;
        if (width < 991) {
          setIsMobile(true);
          setIsDesktop(false);
        } else {
          setIsDesktop(true);
          setIsMobile(false); 
        }
      }, 
      []
    );
  }, [])
  const buttonMobile = {
    color: '#A50113', 
    marginRight:'0.5rem', 
    marginLeft:'0.5rem', 
    background: '#FFFBF2',
    fontSize:'1.20rem', 
    paddingLeft: '2.5%',
    paddingTop: '0rem', 
    marginTop:'0rem',
    borderColor: '#FFFBF2', 
  };

  const buttonLaptop = {
    color: '#A50113', 
    paddingLeft:'10%', 
    background: '#FFFBF2',
    fontSize:'1.20rem', 
    borderColor: '#FFFBF2', 
  };
  const dropMobile = {
    background: '#FFFBF2', 
    border: '#FFFBF2',  
    fontSize:'1.25rem', 
    marginBottom:'0.32rem', 
    borderColor: '#FFFBF2', 
    color: '#A50113'
  };
  const dropLaptop = {
    background: '#FFFBF2', 
    border: '#FFFBF2',  
    fontSize:'1.25rem', 
    marginBottom:'0.32rem', 
    marginTop:'0.32rem', 
    color: '#A50113'
  };
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  function mailProtector() {
    const p = "mailto:enxquixres@offthecurrxiculumx.cox.uxk";
    const mailProtected = p.replace('mailto:enxquixres@offthecurrxiculumx.cox.uxk', 'mailto:enquiries@offthecurriculum.co.uk');
    document.getElementById("navEmail").href = mailProtected;
  }; 

  var location = '/';

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className='align-middle colorNav' sticky="top" variant='dark' bg="navBar">
          <Container fluid>
          <Navbar.Brand href={location}
           style={
          isMobile ? buttonMobile
          : isDesktop ? buttonLaptop 
          : buttonMobile}
          >
          </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} aria-labelledby='toggle menu button'
                          style={{color: '#A50113', background: '#A50113'}}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-label= 'toggle menu button'
              aria-labelledby= 'toggle menu button'
              placement="end"
              style={{color: '#A50113', background: '#FFFBF2'}}
              scroll={true}
            >
              <Offcanvas.Header closeButton style={{}}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div>
                        <img alt="Past Present Tense Logo " className='logoMobile' />
                    </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{width:'91%'}}>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Navbar.Brand style={{color: '#A50113', fontWeight:'bold', paddingTop:'0.4rem'}}>
                    <Button style={
                                    isMobile ? buttonMobile
                                    : isDesktop ? buttonLaptop 
                                    : buttonMobile
                                }
                                >
                        <Link to="/Articles" className='educoNavLink'>
                        ARTICLES
                        </Link>
                    </Button>
                    </Navbar.Brand>
                <Navbar.Brand style={{color: '#A50113', fontWeight:'bold', paddingTop:'0.4rem'}}>
                  <Button style={
                                isMobile ? buttonMobile
                                : isDesktop ? buttonLaptop 
                                : buttonMobile
                              }
                              >
                    <Link to="/Podcasts" className='educoNavLink'>
                      THE PODCAST
                    </Link>
                  </Button>
                </Navbar.Brand>
                <Dropdown className="d-inline mx-2" autoClose={true} style={{}}>
                    <Dropdown.Toggle id="dropdown-autoclose-inside" style={
                                    isMobile ? dropMobile
                                    : isDesktop ? dropLaptop 
                                    : dropMobile}
                                    >
                    FOCUS ON
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item href="/SelfDevelopment">
                        Self-development
                    </Dropdown.Item>
                    <Dropdown.Item href="/Motivation">
                        Motivation
                    </Dropdown.Item>
                    <Dropdown.Item href="/Relationshipwritings">
                        Love and Relationships
                    </Dropdown.Item>
                    <Dropdown.Item href="/Philosophy">
                        Philosophy
                    </Dropdown.Item>
                    <Dropdown.Item href="/ForTheFoodies">
                        For the Foodies
                    </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="d-inline mx-2" autoClose={true}>
                    <Dropdown.Toggle id="dropdown-autoclose-outside" style={
                                                                    isMobile ? dropMobile
                                                                    : isDesktop ? dropLaptop 
                                                                    : dropMobile}
                                                                    >
                    ABOUT
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item href="/PastPresentTense">
                        About Past.Present.Tense
                    </Dropdown.Item>
                    <Dropdown.Item href="/AlexWildWood">
                        About Alex
                    </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </Nav>
                
                <div className='popFooter'>
                  <div className='rowFooter'>
                    <div className='columnNav'>
                      Instagram, Facebook, Twitter
                    </div>
                    <div className='columnNav'>
                    <span className="nav-information"><a className="nav_email" onClick={mailProtector} id="navEmail" href='mailProtector:;'><b>Contact Me!</b></a></span>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
        
      );
    }