import React from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { moreArticles, Articles } from './Media/Index';

function MoreArticles() {
  return (
    <>
    <Row>
    <Col>
        {moreArticles.map((items) => (
            <div className='articleSpacing'>
            <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                <Card.Body>
                    <Card.Title className='cardTitle'> {items.title}</Card.Title>
                    <Card.Text className='cardText'>
                        {items.articleDesc}
                    </Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    <center>
                            <Card.Body>
                                <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#A50113', borderBlockColor:'#A50113', borderColor:'#A50113;'}}>
                                <Card.Link href={items.articlepagehref} className='articleButton'>
                                    Read more here...
                                    </Card.Link>
                                </Button>{' '}
                            </Card.Body>
                        </center>
                </Card>
            </div>
        ))}
    </Col>
    </Row>
    </>
  );
}

export default MoreArticles;