import React, { useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import NobodyKnowsArticle from "../Content/NobodyKnows.md";
import './stylesheet.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


function NobodyKnows () {
        let [content, setContent] = useState({ md: "" });
      
        useEffect(() => {
          fetch(NobodyKnowsArticle)
            .then((response) => response.text())
            .then((md) => {
              setContent({ md });
            });
        }, []);

  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <Container>
      <Row>
        {/*<! Main page we need the course information>*/}
        <Col sm={8}>      
            <Markdown>{content && content.md}</Markdown>
        </Col>
        <Col sm={4}>        
          <div className="course-overview">

          </div>
        </Col>
      </Row>
      </Container>
    </React.Fragment>
  );
};

export default NobodyKnows;