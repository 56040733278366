import React from "react";
import './parallaxLandingImage.css';


function parallaxLandingImage() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <h2 id="parallaxHeader">
                Find Your Calm with
            </h2>
            <h1 id="parallaxText">
                Poco a Poco
            </h1>
            <h5 id="parallaxSubheading">
                by Nicole Curtis, MSc
            </h5>
            <div class="parallax"></div>
        </React.Fragment>
        );
    }
    export default parallaxLandingImage