import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import './latestArticles.css'


function LatestArticles() {

  return (
      <Row xs={1} md={2}>
        <Col>
        <div className='articleSpacing'>
            <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                    <Card.Body>
                        <Card.Title className='cardTitle'>Apophenia</Card.Title>
                        <Card.Text className='cardText'>
                            When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
                        </Card.Text>
                    </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>Cras justo odio</ListGroup.Item>
                            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                        </ListGroup>
                        <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#A50113', borderBlockColor:'#A50113', borderColor:'#A50113;'}}>
                            <Card.Link href="/Apophenia" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
        </div>
        </Col>
        <Col>
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                <Card.Body>
                    <Card.Title className='cardTitle'>Pádraic</Card.Title>
                    <Card.Text className='cardText'>
                        The film The Banshee of Inisherin is a brilliant film. The story is great, the setting is beautiful, the characters are fascinating, and even the frickin' donkey is amazing.
                    </Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#A50113', borderBlockColor:'#A50113', borderColor:'#A50113;'}}>
                            <Card.Link href="/Pádraic" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
        </Col>
        <Col>
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                <Card.Body>
                    <Card.Title className='cardTitle'>Give Up</Card.Title>
                    <Card.Text className='cardText'>
                        Here's a fun question to ask yourself; whatever you want to achieve, your goal for the future or the state of being you wish to get to is, what if it's further away than you think?
                    </Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#A50113', borderBlockColor:'#A50113', borderColor:'#A50113;'}}>
                            <Card.Link href="/GiveUp" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
        </Col>
      </Row>
  );
}

export default LatestArticles;