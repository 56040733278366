import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import './MainPage.css'

function PastPresentTense() {
  return (
    <Container>
      <Row>
        <Col sm={6}>
        <h1>
            About Past Present Tense
        </h1>
        <p> 
            .....
        </p>
        <h1>
            Our mission statement
        </h1>
        <p>
            ....
        </p>
        <h1>
            How we plan to do this...
        </h1>
        <p>
            ....
        </p>
        </Col>
        <Col sm={6}>
        <img src='https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/AboutPics%2FPicAlex.jpg?alt=media&token=3a5e4c44-7324-4634-a7fc-221fa4076467' className="AboutNicoleImage" />
        </Col>
      </Row>
      </Container>
  );
}

export default PastPresentTense;