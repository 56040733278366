import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import AllPodEpisodes from "./AllEpisodes/AllPodEpisodes";

function Podcast() {
  return (
    <Container>
      <Row>
        <Col>
        <h1 className="cardTitle">
            Podcasts
        </h1>
        <p>
          To a world of relationships fostered on connections, a world based on... blah
        </p>
        </Col>
        <Col>
            Insert Image Here
        </Col>
      </Row>
      <h2 className="cardTitle">
        Browse Our Podcast
      </h2>
      <Row xs={1} md={1} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title className="cardTitle">
                The Past. Present. Tense. Podcast
              </Card.Title>
              <Card.Text className="cardText">
                Info about the upcoming podcast
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <small className="text-muted">
                <b>Next episode release date:</b> May 5<sup>th</sup>, 2023 
            </small>
            </Card.Footer>
          </Card>
          <center>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{color: '#ffffff',  backgroundColor: '#A50113', borderBlockColor:'#A50113', borderColor:'#A50113;'}}>
                        Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Spotify</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Tidal</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Google Podcasts</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </center>
        </Col>
    </Row>
    <hr/>
    <h1>
      ALL PODCAST EPISODES
    </h1>
    <div className="ScrollableDiv">
      <AllPodEpisodes />
    </div>
    
    </Container>
  );
}

export default Podcast;