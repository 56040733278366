const Podcast = [
    {
      uid: 1,
      title: "Hope",
      subtitle: "Topic Genre",
      articleDesc: `
            When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
          `,
      articlepagehref: `/Apophenia`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Apophenia Image`,
    }, 
    {
        uid: 2,
        title: "What we think we know",
        subtitle: "Topic Genre",
        articleDesc: `
              When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
            `,
        articlepagehref: `/Apophenia`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Apophenia Image`,
      }, 
      {
        uid: 3,
        title: "What we can do?",
        subtitle: "Topic Genre",
        articleDesc: `
              When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
            `,
        articlepagehref: `/Apophenia`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Apophenia Image`,
      }, 
      {
        uid: 4,
        title: "Who we are?",
        subtitle: "Topic Genre",
        articleDesc: `
              When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
            `,
        articlepagehref: `/Apophenia`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Apophenia Image`,
      }
]

export {
    Podcast 
}