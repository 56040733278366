const moreArticles = [
    {
      uid: 1,
      title: "Apophenia",
      subtitle: "Topic Genre",
      articleDesc: `
            When the Beatles released Revolution 9 using a technique called backmasking to record the song in reverse, people thought it was a secret message to say that young Paul McCartney had passed off this mortal coil.
          `,
      articlepagehref: `/Apophenia`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Apophenia Image`,
    },
    {
        uid: 2,
        title: "Gumption Gaps",
        subtitle: "Course Homepage",
        articleDesc: `
            Have you ever had something you knew you wanted to do, and yet you still procrastinated doing it? Or maybe something you know you should do but can’t get started? 
            `,
        articlepagehref: `/GumptionGaps`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `GumptionGaps Image`,
      },
      {
        uid: 3,
        title: "Pádraic",
        subtitle: "Course Homepage",
        articleDesc: `
            The film The Banshee of Inisherin is a brilliant film. The story is great, the setting is beautiful, the characters are fascinating, and even the frickin' donkey is amazing.
            `,
        articlepagehref: `/Pádraic`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Pádraic Image`,
      },
      {
        uid: 4,
        title: "Give Up",
        subtitle: "Course Homepage",
        articleDesc: `
        Here's a fun question to ask yourself; whatever you want to achieve, your goal for the future or the state of being you wish to get to is, what if it's further away than you think? 
            `,
        articlepagehref: `/GiveUp`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `GiveUp Image`,
      },
      {
        uid: 5,
        title: "Three Recipes",
        subtitle: "Course Homepage",
        articleDesc: `
        Take 40g of whole grain oats, 40g of buckwheat and 200ml of water and soak overnight or for at least two hours. Add 40g of your favourite vanilla plant protein, 200 ml of plant milk and cinnamon to taste...
            `,
        articlepagehref: `/ThreeRecipes`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `ThreeRecipes Image`,
      },
      {
        uid: 6,
        title: "Pizza Ladder",
        subtitle: "Course Homepage",
        articleDesc: `
        Take 40g of whole grain oats, 40g of buckwheat and 200ml of water and soak overnight or for at least two hours. Add 40g of your favourite vanilla plant protein, 200 ml of plant milk and cinnamon to taste...
            `,
        articlepagehref: `/PizzaLadder`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Pizza Ladder Image`,
      },
      {
        uid: 7,
        title: "Bubblegum",
        subtitle: "Course Homepage",
        articleDesc: `
        Most of us spend a good portion of our lives walking around with a cheque in our pocket. A metaphorical cheque because it's 2023, and nobody uses cheques anymore, but still...
            `,
        articlepagehref: `/Bubblegum`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Bubblegum Image`,
      },
      {
        uid: 8,
        title: "Bricklayer",
        subtitle: "Course Homepage",
        articleDesc: `
        A brand new year. A fresh start. A blank canvas. Yes, it's that time of year when we decide we're going to do something new, different and exciting, and we almost always give up on it by February....
            `,
        articlepagehref: `/Bricklayer`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Bricklayer Image`,
      },
      {
        uid: 9,
        title: "Time Travel",
        subtitle: "Course Homepage",
        articleDesc: `
        No regrets. It sounds like a great platitude to live by and also makes for really hilarious tattoos, but to truly live by such a maxim can rob us of so much life has to offer. As we close each year....
            `,
        articlepagehref: `/TimeTravel`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `TimeTravel Image`,
      },
      {
        uid: 10,
        title: "Olives, Doughnuts & Hamburgers",
        subtitle: "Course Homepage",
        articleDesc: `
        It's tough to think like an olive farmer. Every olive you've ever eaten has been grown from a tree that was grown from another tree that was grown from another tree. Not as you might think by taking the olive pit, but by grafting. ....
            `,
        articlepagehref: `/OlivesDoughnutsHamburgers`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Olives, Doughnuts, and Hamburgers Image`,
      },
      {
        uid: 11,
        title: "Brief Thoughts on Belief",
        subtitle: "Course Homepage",
        articleDesc: `
        In the mid-nineteenth century, the medical industry wasn't exactly the scientific bastion we think of it as today. Although there were significant advances like the x-ray, anaesthesia and blood transfusion....
            `,
        articlepagehref: `/BriefThoughtsOnBelief`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Brief Thoughts on Belief Image`,
      },
      {
        uid: 12,
        title: "The Slippery Slope",
        subtitle: "Course Homepage",
        articleDesc: `
        If I give you a piece of chocolate, I'll have to give everyone else a bit too. We can't give people benefits. If the government dishes out help left, right, and centre, pretty soon, no one will have any motivation, and we'll live in a nanny state.....
            `,
        articlepagehref: `/TheSlipperySlope`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `The Slippery Slope Image`,
      },
      {
        uid: 13,
        title: "Affirming the Consequent",
        subtitle: "Course Homepage",
        articleDesc: `
        Horses and carts have forever been a point of contention for our species. Hopelessly mixing up carts before horses and horses running wild like black beauty had two hits of intravenous coffee.....
            `,
        articlepagehref: `/AffirmingtheConsequent`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Affirming the Consequent Image`,
      },
      {
        uid: 13,
        title: "Appeal To Consequences",
        subtitle: "Course Homepage",
        articleDesc: `
        Remember the good old days when smoking was good for you? What a time. You could pick up a handy pack of life-giving health sticks to spark up and enjoy throughout the day......
            `,
        articlepagehref: `/AppealToConsequences`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Affirming the Consequent Image`,
      },
      {
        uid: 14,
        title: "Puppeteer",
        subtitle: "Course Homepage",
        articleDesc: `
        When you're feeling really good about yourself, and you're gliding along as you make your way home. People are smiling at you like you're Mary Poppins, and then you open up social media on your phones......
            `,
        articlepagehref: `/Puppeteer`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Puppeteers Image`,
      },
      {
        uid: 15,
        title: "Bunny Wars",
        subtitle: "Course Homepage",
        articleDesc: `
        You know that friend you've got, Bill. He's got some interesting opinions and makes slightly weird jokes sometimes, but they’re just jokes, so it’s no big deal, right?......
            `,
        articlepagehref: `/`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Puppeteers Image`,
      }, 
      {
        uid: 16,
        title: "Sticks In Water",
        subtitle: "Course Homepage",
        articleDesc: `
        The pre-Socratic philosopher Parmenides famously said that what is is, and what is not is not. Sounds pretty reasonable, right? In fact, it doesn't sound that profound at all......
            `,
        articlepagehref: `/SticksInWater`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `SticksInWater Image`,
      }, 
      {
        uid: 17,
        title: "Optimism and Positive Thinking",
        subtitle: "Course Homepage",
        articleDesc: `
        Remember that smarmy friend you've got, the one who seems to walk around acting like they know something everyone else has yet to discover? Sometimes you wonder why you're still friends......
            `,
        articlepagehref: `/OptimismAndPositiveThinking`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Optimism And Positive Thinking Image`,
      }, 
      {
        uid: 18,
        title: "Love As Art Pt.3",
        subtitle: "Course Homepage",
        articleDesc: `
        Have you ever heard the statement "wherever you go, there you are"? If you have, you might have initially expected it to come from Baby Yoda or a four-year-old telling you the sky is blue......
            `,
        articlepagehref: `/LoveAsArtPt3`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Love As Art Pt3 Image`,
      }, 
      {
        uid: 19,
        title: "Love As Art Pt.2",
        subtitle: "Course Homepage",
        articleDesc: `
        Supermarkets are like casinos. They’re built to make you forget you have any life outside the colourful isles and non-descript pop music. There are no windows......
            `,
        articlepagehref: `/LoveAsArtPt2`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Love As Art Pt2 Image`,
      }, 
      {
        uid: 20,
        title: "Love As Art Pt.1",
        subtitle: "Course Homepage",
        articleDesc: `
        I started playing the guitar when I was pretty young, and let me tell you, I sucked. I boy, oh boy, did I suck. I remember looking at my fingers, trying to make them contort......
            `,
        articlepagehref: `/LoveAsArtPt1`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Love As Art Pt1 Image`,
      }, 
      {
        uid: 21,
        title: "The Authenticity Trap",
        subtitle: "Course Homepage",
        articleDesc: `
        When was the last time you felt like you did something that was authentically "you"?
        You could say you felt like eating or skipping breakfast this morning, and that was you acting authentically, but we generally don't think of authenticity in that way......
            `,
        articlepagehref: `/TheAuthenticityTrap`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `The Authenticity Trap Image`,
      }, 
      {
        uid: 22,
        title: "Fathers Day",
        subtitle: "Course Homepage",
        articleDesc: `
        Today it is Fathers Day in New Zealand, where I've lived most of my life and where my father, along with the rest of my immediate family currently reside......
            `,
        articlepagehref: `/FathersDay`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Fathers Day Image`,
      }, 
      {
        uid: 22,
        title: "Practice & Discipline",
        subtitle: "Course Homepage",
        articleDesc: `
        There's a big difference between practice and discipline. Practice is something intrinsic. It’s coming from a place within that is focused on slowly getting better at something over time......
            `,
        articlepagehref: `/PracticeAndDiscipline`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Practice & Discipline Image`,
      }, 
      {
        uid: 23,
        title: "Consistent",
        subtitle: "Course Homepage",
        articleDesc: `
        Our culture praises consistent people, people who show up time and time again. Even those who stick with one hobby for a long a period of time are looked at as if they have transcended......
            `,
        articlepagehref: `/Consistent`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Consistent Image`,
      }, 
      {
        uid: 24,
        title: "Flies and Frogs",
        subtitle: "Course Homepage",
        articleDesc: `
        “I feel thin, sort of stretched, like butter scraped over too much bread.” - Bilbo Baggins. There are times when it can feel as if we can't keep up with the pace of life. We can almost physically experience......
            `,
        articlepagehref: `/Consistent`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Flies and Frogs Image`,
      },       
      {
        uid: 24,
        title: "Currents",
        subtitle: "Course Homepage",
        articleDesc: `
        When was the last time you went for a swim? That feeling of weightlessness and freedom. The power of the ocean is moving you to its rhythm......
            `,
        articlepagehref: `/Currents`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Currents Image`,
      }, 
      {
        uid: 25,
        title: "Three Simple Questions",
        subtitle: "Course Homepage",
        articleDesc: `
        So often, we want to make changes in our life. They can range from significant decisions like having children or moving cities or small ones like do I......
            `,
        articlepagehref: `/ThreeSimpleQuestions`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Three Simple Questions Image`,
      }, 
      {
        uid: 26,
        title: "Empty Cup",
        subtitle: "Course Homepage",
        articleDesc: `
        Once upon a time, there was a wise Zen master. They probably lived up a big mountain, as most Zen-like masters seem to do......
            `,
        articlepagehref: `/EmptyCup`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Empty Cup Image`,
      }, 
      {
        uid: 27,
        title: "ET and Burritos",
        subtitle: "Course Homepage",
        articleDesc: `
        I love burritos. To be honest, I love pretty much any Mexican food, but burritos are unique. Even the design of a burrito, a cylinder shape enclosing various fillings......
            `,
        articlepagehref: `/ETAandBurritos`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `ETA and Burritos Image`,
      }, 
      {
        uid: 28,
        title: "Stadiums and Supermarkets",
        subtitle: "Course Homepage",
        articleDesc: `
        If you’ve ever been fortunate enough to go to a true stadium event you’ll know the buzz and excitement that can get stirred up when there’s thousands of people fuelled with tribal identity, status and probably alcohol.......
            `,
        articlepagehref: `/StadiumsAndSupermarkets`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Stadiums And Supermarkets Image`,
      }, 
      {
        uid: 29,
        title: "Needy Needs",
        subtitle: "Course Homepage",
        articleDesc: `
        Have you ever wanted to say something but didn't? Have you ever noticed yourself later resenting someone because they haven't telepathically picked up what you're not putting down? .......
            `,
        articlepagehref: `/NeedyNeeds`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Needy Needs Image`,
      }, 
      {
        uid: 30,
        title: "Conspiracies of Self",
        subtitle: "Course Homepage",
        articleDesc: `
        Have you ever wanted to say something but didn't? Have you ever noticed yourself later resenting someone because they haven't telepathically picked up what you're not putting down? .......
            `,
        articlepagehref: `/ConspiraciesOfSelf`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Conspiracies of Self Image`,
      }, 
      {
        uid: 31,
        title: "Lightning McQueen",
        subtitle: "Course Homepage",
        articleDesc: `
        Have you seen that animated film Cars? It follows the main character, race car Lightning McQueen, who becomes lost after accidentally falling out of his trailer truck.......
            `,
        articlepagehref: `/LightingMcQueen`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Lighting McQueen Image`,
      }, 
      {
        uid: 32,
        title: "Pan",
        subtitle: "Course Homepage",
        articleDesc: `
        Imagine you're walking home late at night, maybe in a foreign country you don't know all that well. It's dark, and as you make your way back to your accommodation you turn to the alley.......
            `,
        articlepagehref: `/Pan`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Pan Image`,
      }, 
      {
        uid: 33,
        title: "Turbulence",
        subtitle: "Course Homepage",
        articleDesc: `
        It was a beautiful sunny Los Angeles day as our flight took off, bound for London. Headed towards the clear, wide, open blue with nothing but the hum of the engines, mummer of onboard chatter and the occasional.......
            `,
        articlepagehref: `/Turbulence`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Turbulence Image`,
      }, 
      {
        uid: 34,
        title: "Reptilians",
        subtitle: "Course Homepage",
        articleDesc: `
        When was the last time your phone rang and you saw your boss’s number, the bank, a friend or family member you've been avoiding and thought.......
            `,
        articlepagehref: `/Reptilians`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Reptilians Image`,
      }, 
      {
        uid: 34,
        title: "Love Handles",
        subtitle: "Course Homepage",
        articleDesc: `
        I really love good movies. Ones where the shots are thought out, the scenes are beautiful, the acting is on point and the storyline has me submerged into it, so much so that I am screaming out.......
            `,
        articlepagehref: `/LoveHandles`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Love Handles Image`,
      }, 
      {
        uid: 35,
        title: "Everything All The Time",
        subtitle: "Course Homepage",
        articleDesc: `
        There's a paradox when we think about what we want to achieve - we desire something to happen, but they all happen at a future point.......
            `,
        articlepagehref: `/EverythingAllTheTime`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Everything All The Time Image`,
      }, 
      {
        uid: 36,
        title: "Maps",
        subtitle: "Course Homepage",
        articleDesc: `
        Maps can be beautiful. Full of fine details and markers, wonderfully coloured with a scaled gradient on the side. It used to be a popular thing to have a big one.......
            `,
        articlepagehref: `/Maps`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Maps Image`,
      },
      {
        uid: 37,
        title: "A Dog Can Dream",
        subtitle: "Course Homepage",
        articleDesc: `
        I love dogs. Unless you're a complete psychopath, who wouldn't want a four-legged bundle of joy to greet you when you get home from work, wake you up in the morning.......
            `,
        articlepagehref: `/ADogCanDream`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `A Dog Can Dream Image`,
      },  
      {
        uid: 38,
        title: "Pro-Wrestling VS Gardening",
        subtitle: "Course Homepage",
        articleDesc: `
        It's a lot of fun to sit down with a close friend, your partner or even yourself and think about what the future might hold. Let yourself imagine freely all the things you want to do.......
            `,
        articlepagehref: `/ProWrestlingVSGardening`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Pro-Wrestling VS Gardening Image`,
      }, 
      {
        uid: 39,
        title: "False Positives",
        subtitle: "Course Homepage",
        articleDesc: `
        Remember when you were first learning to ride a bike? People often say things are like riding a bike, meaning they come back to you easily. But what about the first time you rode a bike.......
            `,
        articlepagehref: `/FalsePositives`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `False Positives Image`,
      },   
      {
        uid: 40,
        title: "The Messy Middle",
        subtitle: "Course Homepage",
        articleDesc: `
        In 2001 having survived the Y2k bug scare, the world breathed a short sigh of relief before hearing the wise words penned by two brothers.......
            `,
        articlepagehref: `/TheMessyMiddle`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `The Messy Middle Image`,
      }, 
      {
        uid: 41,
        title: "Get Up and Goal",
        subtitle: "Course Homepage",
        articleDesc: `
        One evening after a long day in the studio, I was standing in the kitchen thinking about the glorious dinner I was about to prepare when suddenly panic hit. I noticed that my step count for the day hadn’t been reached........
            `,
        articlepagehref: `/GetUpAndGoal`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Get Up And Goal Image`,
      }, 
      {
        uid: 42,
        title: "Trade-Offs",
        subtitle: "Course Homepage",
        articleDesc: `
        You can’t have your cake and eat it too has got to be one of the dumbest platitudes ever bestowed upon the modern world. Next time you’re at a birthday party, and they roll out the main event........
            `,
        articlepagehref: `/TradeOff`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Trade Off Image`,
      }, 
      {
        uid: 43,
        title: "Dolphines",
        subtitle: "Course Homepage",
        articleDesc: `
        We love to believe that our lives are elevators. That we can simply have an idea of where we want to end up, pound a few buttons and set ourselves on our way ascending to the promised floor......
            `,
        articlepagehref: `/Dolphines`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Dolphines Image`,
      }, 
      {
        uid: 44,
        title: "Planning",
        subtitle: "Course Homepage",
        articleDesc: `
        Picture yourself hiking through the glorious mountains of Switzerland. The views are amazing and the snow covered peaks look like postcards from the final scene of The Sound of Music......
            `,
        articlepagehref: `/Planning`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Planning Image`,
      },      
      {
        uid: 45,
        title: "When to Ask Why",
        subtitle: "Course Homepage",
        articleDesc: `
        As a deeply curious person, I have an insatiable desire to know what something ‘is’. As if all things have a bottom to them....
            `,
        articlepagehref: `/WhenToAskWhy`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `When To Ask Why Image`,
      }, 
      {
        uid: 46,
        title: "Feedback",
        subtitle: "Course Homepage",
        articleDesc: `
        When I was growing up I loved guitar feedback. The howl, the roar, the siren, the wild energy.I played in bands and the feedback acted like a signal that something exciting was about to happen.....
            `,
        articlepagehref: `/Feedback`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Feedback Image`,
      }, 
      {
        uid: 47,
        title: "Problems and Solutions",
        subtitle: "Course Homepage",
        articleDesc: `
        Once whilst on a surf trip my friend Chris spilt some red wine across the carpet of the lounge in the Air BnB we were staying in.....
            `,
        articlepagehref: `/ProblemsAndSolutions`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Problems And Solutions Image`,
      }, 
      {
        uid: 48,
        title: "The New Norm",
        subtitle: "Course Homepage",
        articleDesc: `
        In some parts of the world it would be completely normal to be served a meal containing dog meat. But where I live most people would be very uncomfortable to know that Lassie was on their plate.....
            `,
        articlepagehref: `/TheNewNorm`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `The New Norm Image`,
      },
      {
        uid: 49,
        title: "Spending Happiness",
        subtitle: "Course Homepage",
        articleDesc: `
        Like time, happiness is something you can’t save but only spend. The funny thing about happiness is that when you’re looking for it the most, you can’t seem to find it but it always shows up when you aren’t......
            `,
        articlepagehref: `/SpendingHappiness`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Spending Happiness Image`,
      },
      {
        uid: 50,
        title: "Nobody Knows What They’re Doing with Their Lives",
        subtitle: "Course Homepage",
        articleDesc: `
        When I was a kid I really wanted to be a Ninja Turtle. I wished I could just get hit with some nuclear waste and my pet tortoise Speedy would be close by so I could leave behind this human body finally embrace my calling.......
            `,
        articlepagehref: `/NobodyKnows`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Nobody Knows Image`,
      },
      {
        uid: 51,
        title: "Younger Today",
        subtitle: "Course Homepage",
        articleDesc: `
        We all look forward to a new year. A clean slate, a fresh start, the idea of a blank calendar with a world of possibilities in front of us........
            `,
        articlepagehref: `/YoungerToday`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Younger Today Image`,
      },
      {
        uid: 52,
        title: "Measuring Backwards",
        subtitle: "Course Homepage",
        articleDesc: `
        A couple of years ago I made a goal to read 50 books in one year. At the time I think I had seen somewhere that Bill Gates reads about one book a day so I figured I’m probably seven times dumber than Bill, so one a week-ish seemed doable.........
            `,
        articlepagehref: `/MeasuringBackwards`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Measuring Backwards Image`,
      },
      {
        uid: 53,
        title: "Not Everything Scales",
        subtitle: "Course Homepage",
        articleDesc: `
        If you got 10 times better at something, would you enjoy it 10 times more? If you could watch a movie on a device that is twice as good as the one you currently have, would you enjoy the film twice as much?.........
            `,
        articlepagehref: `/NotEverythingScales`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Not Everything Scales Image`,
      },
      {
        uid: 54,
        title: "Shallow Criticism",
        subtitle: "Course Homepage",
        articleDesc: `
        It hurts to be criticised. Especially for no good reason. When we’re criticised with no explanation or a very shallow one what we’ve done is surprised expectations.........
            `,
        articlepagehref: `/ShallowCriticism`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Shallow Criticism Image`,
      },
      {
        uid: 55,
        title: "Brush Your Teeth",
        subtitle: "Course Homepage",
        articleDesc: `
        Do you get inspired to brush your teeth? When was the last time you were sitting in your lounge, driving in your car or walking somewhere when suddenly you were struck with the divine call.........
            `,
        articlepagehref: `/BrushYourTeeth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Brush Your Teeth Image`,
      },
      {
        uid: 56,
        title: "Turkey Pancakes",
        subtitle: "Course Homepage",
        articleDesc: `
        A turkey cooked 80% of the way through is totally useless. Making pancake batter and not cooking the actual pancakes is pointless and in both cases you’ll end up with some disappointed guests..........
            `,
        articlepagehref: `/TurkeyPancakes`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Turkey Pancakes Image`,
      },
      {
        uid: 57,
        title: "35 Pieces of Wisdom I’ve Learned",
        subtitle: "Course Homepage",
        articleDesc: `
        Originally I posted  these on my Instagram for my birthday but the response I got to it was quite overwhelming so I thought I’d share it again here...........
            `,
        articlepagehref: `/PiecesOfWisdomIveLearned`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Pieces Of Wisdom Ive Learned Image`,
      },
]

export { moreArticles };