import React from "react";
import './aboutMe.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from "react-bootstrap";
function aboutMe() {
    const width = "100%";
    return ( 
        <React.Fragment>
        <Container>
        <Row>
        <Col sm={6}>
        <img src='https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/AboutPics%2FPicAlex.jpg?alt=media&token=3a5e4c44-7324-4634-a7fc-221fa4076467' className="AboutNicoleImage" />
        </Col>
        <Col sm={6}>
            <h1 className="Header">
                About Alex Wildwood
            </h1>
            <p>
                I grew up sailing around the world on a yacht. Yeah, I know pretty out there. My parents were like conservative hippies or something... anyways, we ended up in New Zealand and that’s where I’ve spent the majority of my life. I was first interested in music pretty early on and I’ve followed that curiosity ever since. That lead me to writing songs, playing in bands, being a solo artist then back in a band and finally into being a music producer and songwriter. Writing and creating songs has always been my favourite part of music, it’s the thing I can continually lose myself in and a frustration and discomfort I’m willing to feel to get a song into what it’s trying to be. Over my time in music I’ve toured with numerous artists, played huge shows, slept on floors, lived from dumpster food, recorded in some of the most beautiful studios and holiday homes, houses and garages, I’ve received gold plaques and been a part of creating music for a major motion picture as well released songs no one cared about. It’s been an incredible journey so far. I currently reside between London, England and Auckland, New Zealand. What about you, what’s your story?
            </p>
        </Col>
        </Row>
        </Container>
        </React.Fragment>
        );
    }
    export default aboutMe;