import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return ( 
        <React.Fragment>
        <footer class="site-footer">
        <div class="container">
            <div class="row">
            <div class="col-sm-12 col-md-6">
                <h6>About</h6>
                <p className="footerText">
                    pastpresenttense.co.uk <i>SMALL STEPS IN THE RIGHT DIRECTION CAN CHANGE A MIND FOREVER </i> info info..........................................................
                    ..............................................................
                    .............................................................. 
                </p>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Categories</h6>
                <ul class="footer-links">
                <li>
                    <Link to="/SelfDevelopment">
                        Self-Development
                    </Link>
                </li>
                <li>
                    <Link to="/Motivation">
                        Motivation
                    </Link>
                </li>
                <li>
                    <Link to="/Relationshipwritings">
                        Love and Relationships
                    </Link>
                </li>
                <li>
                    <Link to="/Philosophy">
                        Philosophy
                    </Link>
                </li>
                <li>
                    <Link to="/ForTheFoodies">
                        For The Foodies
                    </Link>
                </li>
                </ul>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul class="footer-links">
                <li><Link to="/About">About Past. Present. Tense</Link></li>
                <li><Link to="/AlexWildWood">About Alex Wildwood</Link></li>
                <li><a href="/">Contribute</a></li>
                <li><a href="/">Privacy Policy</a></li>
                <li><a href="/">Terms and Conditions</a></li>
                </ul>
            </div>
            </div>
            <hr />
        </div>
        <div class="container">
            <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p className="footerText">Copyright &copy; 2023 All Rights Reserved by Levermore and Code.
                </p>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
                <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>   
                </ul>
            </div>
            </div>
        </div>
    </footer>
        </React.Fragment>
        );
    }
    export default Footer