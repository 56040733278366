import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer, NavBar } from './components/common';
import { Main } from './components/landingPage';
import Articles from './components/articles/mainPage/mainPage.js'; 
///Importing the focus tabbed pages
import {
  Motivation, 
  SelfDevelopment, 
  ForTheFoodies, 
  Adultmindfulness, 
  Relationshipwritings, 
  Philosophy
} from './components/focusOn/index';
///Import the About us pages
import {
  AlexWildWood, 
  PastPresentTense
} from './components/about/index';
/// Import podcast page 
import Podcast from './components/podcast/Mainpage';

///Articles
import {
  Apophenia, 
  Pádraic, 
  GiveUp, 
  PiecesOfWisdomIveLearned, 
  ADogCanDream, 
  AffirmingtheConsequent, 
  AppealToConsequences, 
  Bricklayer, 
  BriefThoughtsOnBelief, 
  BrushYourTeeth, 
  Bubblegum, 
  Currents,
  ConspiraciesOfSelf,
  Consistent, 
  Dolphines, 
  EmptyCup, 
  ETAandBurritos, 
  EverythingAllTheTime, 
  FalsePositives, 
  FathersDay, 
  Feedback, 
  FilesandFrogs, 
  GetUpAndGoal, 
  GumptionGaps, 
  LightingMcQueen, 
  LoveAsArtPt1, 
  LoveAsArtPt2, 
  LoveAsArtPt3, 
  LoveHandles, 
  Maps, 
  MeasuringBackwards, 
  NeedyNeeds, 
  NobodyKnows, 
  NotEverythingScales, 
  OlivesDoughnutsHamburgers, 
  OptimismAndPositiveThinking, 
  Pan, 
  PizzaContent,  
  Planning, 
  PracticeAndDiscipline, 
  ProWrestlingVSGardening, 
  ProblemsAndSolutions, 
  Puppeteer, 
  Reptilians, 
  ShallowCriticism, 
  SpendingHappiness, 
  StadiumsAndSupermarkets,
  SticksInWater, 
  TheAuthenticityTrap, 
  TheMessyMiddle, 
  TheNewNorm, 
  TheShortLongRun, 
  TheSlipperySlope, 
  ThreeRecipes, 
  ThreeSimpleQuestions, 
  TimeTravel,
  TradeOff, 
  Turbulence, 
  TurkeyPancakes, 
  WhenToAskWhy, 
  YoungerToday
} from './Pages/Articles/index'

import ArticleTemplatePage from './Pages/Articles/TemplatePage/ArticleTemplatePage';
import ArticleTwo from './Pages/Articles/TemplatePage/ArticleTwo';
import ArticleName from './Pages/Articles/TemplatePage/ArticleThree';

function App() {
  
  return (
    <Router>
     <Header/>
     <NavBar />
        <Routes>
          {/*Main landing page*/}
          <Route path="/" element={<Main />} />
          {/*Routing the main blog page*/}
          <Route path='/Articles' element={<Articles />} />
          {/*Routing the Focus tabbed pages into the router system*/}
          <Route path='/Motivation' element={<Motivation />} />
          <Route path='/SelfDevelopment' element={<SelfDevelopment />} />
          <Route path='/Relationshipwritings' element={<Relationshipwritings />} />
          <Route path='/Adolescentmindfulness' element={<Adultmindfulness />} />
          <Route path='/ForTheFoodies' element={<ForTheFoodies />} />
          <Route path='/Philosophy' element={<Philosophy />} />
          {/*Routing the About Us pages*/}
          <Route path='/AlexWildWood' element={<AlexWildWood />} />
          <Route path='/PastPresentTense' element={<PastPresentTense />} />
          {/*Routing the Podcast page*/}
          <Route path='/Podcasts' element={<Podcast />} />
          {/*Routing the News page*/}
          <Route path='/Apophenia' element={<Apophenia />} />
          <Route path='/GumptionGaps' element={<GumptionGaps />} />
          <Route path='/Pádraic' element={<Pádraic />} />
          <Route path='/GiveUp' element={<GiveUp />} />
          <Route path='/PiecesOfWisdomIveLearned' element={<PiecesOfWisdomIveLearned />} />
          <Route path='/ADogCanDream' element={<ADogCanDream />} />
          <Route path='/AffirmingtheConsequent' element={<AffirmingtheConsequent />} />
          <Route path='/AppealToConsequences' element={<AppealToConsequences />} />
          <Route path='/Bricklayer' element={<Bricklayer />} />
          <Route path='/BriefThoughtsOnBelief' element={<BriefThoughtsOnBelief />} />
          <Route path='/BrushYourTeeth' element={<BrushYourTeeth />} />
          <Route path='/Bubblegum' element={<Bubblegum />} />
          <Route path='/Currents' element={<Currents />} />
          <Route path='/ConspiraciesOfSelf' element={<ConspiraciesOfSelf />} />
          <Route path='/Consistent' element={<Consistent />} />
          <Route path='/Dolphines' element={<Dolphines />} />
          <Route path='/EmptyCup' element={<EmptyCup />} />
          <Route path='/ETAandBurritos' element={<ETAandBurritos />} />
          <Route path='/EverythingAllTheTime' element={<EverythingAllTheTime />} />
          <Route path='/FalsePositives' element={<FalsePositives />} />
          <Route path='/FathersDay' element={<FathersDay />} />
          <Route path='/Feedback' element={<Feedback />} />
          <Route path='/FilesandFrogs' element={<FilesandFrogs />} />
          <Route path='/GetUpAndGoal' element={<GetUpAndGoal />} />
          <Route path='/LightingMcQueen' element={<LightingMcQueen />} />
          <Route path='/LoveAsArtPt1' element={<LoveAsArtPt1 />} />
          <Route path='/LoveAsArtPt2' element={<LoveAsArtPt2 />} />
          <Route path='/LoveAsArtPt3' element={<LoveAsArtPt3 />} />
          <Route path='/LoveHandles' element={<LoveHandles />} />
          <Route path='/Maps' element={<Maps />} />
          <Route path='/MeasuringBackwards' element={<MeasuringBackwards />} />
          <Route path='/NeedyNeeds' element={<NeedyNeeds />} />
          <Route path='/NobodyKnows' element={<NobodyKnows />} />
          <Route path='/NotEverythingScales' element={<NotEverythingScales />} />
          <Route path='/OlivesDoughnutsHamburgers' element={<OlivesDoughnutsHamburgers />} />
          <Route path='/OptimismAndPositiveThinking' element={<OptimismAndPositiveThinking />} />
          <Route path='/Pan' element={<Pan />} />
          <Route path='/PizzaLadder' element={<PizzaContent />} />
          <Route path='/Planning' element={<Planning />} />
          <Route path='/PracticeAndDiscipline' element={<PracticeAndDiscipline />} />
          <Route path='/ProWrestlingVSGardening' element={<ProWrestlingVSGardening />} />
          <Route path='/ProblemsAndSolutions' element={<ProblemsAndSolutions />} />
          <Route path='/Puppeteer' element={<Puppeteer />} />
          <Route path='/Reptilians' element={<Reptilians />} />
          <Route path='/ShallowCriticism' element={<ShallowCriticism />} />
          <Route path='/SpendingHappiness' element={<SpendingHappiness />} />
          <Route path='/StadiumsAndSupermarkets' element={<StadiumsAndSupermarkets />} />
          <Route path='/SticksInWater' element={<SticksInWater />} />
          <Route path='/TheAuthenticityTrap' element={<TheAuthenticityTrap />} />
          <Route path='/TheMessyMiddle' element={<TheMessyMiddle />} />
          <Route path='/TheNewNorm' element={<TheNewNorm />} />
          <Route path='/TheShortLongRun' element={<TheShortLongRun />} />
          <Route path='/TheSlipperySlope' element={<TheSlipperySlope />} />
          <Route path='/ThreeRecipes' element={<ThreeRecipes />} />

          <Route path='/ThreeSimpleQuestions' element={<ThreeSimpleQuestions />} />
          <Route path='/TimeTravel' element={<TimeTravel />} />
          <Route path='/TradeOff' element={<TradeOff />} />
          <Route path='/Turbulence' element={<Turbulence />} />
          <Route path='/TurkeyPancakes' element={<TurkeyPancakes />} />
          <Route path='/WhenToAskWhy' element={<WhenToAskWhy />} />
          <Route path='/YoungerToday' element={<YoungerToday />} />



        </Routes>
      <Footer/>

    </Router>
  );
}

export default App;