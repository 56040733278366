import React from "react";
import { Container } from "react-bootstrap";
import { 
    LatestArticles, 
    SignUpNewsLetter, 
    MoreArticles
} from "../index";
import './mainPage.css';



function Articles() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <Container>
                <h1>
                    ARTICLES
                </h1>
                <div className="SpacingDivs">
                    <LatestArticles />
                </div>
                    <hr/>
                    <div className="SpacingDivs">
                        <SignUpNewsLetter />
                    </div>
                <hr/>
                <h1>
                    EXPLORE MORE
                </h1>
                <div className="ScrollableDiv">
                    <MoreArticles />
                </div>
            </Container>
        </React.Fragment>
        );
    }

    export default Articles;